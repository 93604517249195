import type { ISiteApis } from '@wix/yoshi-flow-editor';

import {
  GROUPS_APP_DEFINITION_ID,
  MEMBERS_APP_DEFINITION_ID,
  PAID_PLANS_APP_DEFINITION_ID,
} from '../constants';

type MembersAreaRoute = {
  home: boolean;
  path: string;
  private: boolean;
  state: string;
  widgetId: string;
};

const MA_PROFILE_WIDGET_ID = '14dbefb9-3b7b-c4e9-53e8-766defd30587';

/**
 * Resolve verticals base urls
 * please bear in mind that object keys should be named the same as routes names.
 * router will inject baseUrls to them during registration stage
 */
export async function resolveVerticalsBaseUrls(api: ISiteApis) {
  const [group, groups, pricingPlans, members, membersProfile] =
    await Promise.all([
      getGroupPageUrl(api),
      getGroupListPageUrl(api),
      getPricingPlansPageUrl(api),
      getMembersPageUrl(api),
      getMembersProfilePageUrl(api),
    ]);

  return {
    group,
    groups,
    members,
    'pricing-plans': pricingPlans,
    'members.profile': membersProfile,
  };
}

function getGroupPageUrl(site: ISiteApis) {
  return getComponentUrl(
    site,
    {
      sectionId: 'group',
      appDefinitionId: GROUPS_APP_DEFINITION_ID,
    },
    '/group',
  );
}

function getGroupListPageUrl(site: ISiteApis) {
  return getComponentUrl(
    site,
    {
      sectionId: 'groups',
      appDefinitionId: GROUPS_APP_DEFINITION_ID,
    },
    '/groups',
  );
}

function getPricingPlansPageUrl(site: ISiteApis) {
  return getComponentUrl(
    site,
    {
      sectionId: 'membership_plan_picker_tpa',
      appDefinitionId: PAID_PLANS_APP_DEFINITION_ID,
    },
    '/plans-pricing',
  );
}

async function getMembersPageUrl(site: ISiteApis) {
  try {
    const membersApi = await site.getPublicAPI(MEMBERS_APP_DEFINITION_ID);

    return membersApi
      .getMemberPagePrefix({ type: 'public' })
      .then((data: { prefix: string }) => `/${data.prefix}`)
      .catch(() => '/profile');
  } catch {
    return '/profile';
  }
}

async function getMembersProfilePageUrl(site: ISiteApis) {
  try {
    const membersApi = await site.getPublicAPI(MEMBERS_APP_DEFINITION_ID);
    const routes: MembersAreaRoute[] = await membersApi.getRoutes();

    const profile = routes.find(
      (route) => route.widgetId === MA_PROFILE_WIDGET_ID,
    );

    return profile ? `/${profile.path}` : '/profile';
  } catch {
    return '/profile';
  }
}

async function getComponentUrl(
  site: ISiteApis,
  sectionIdentifier: {
    appDefinitionId: string;
    sectionId: 'groups' | 'group' | 'membership_plan_picker_tpa' | 'about';
  },
  fallback: string,
) {
  try {
    const { relativeUrl } = await site.getSectionUrl(sectionIdentifier);

    return relativeUrl || fallback;
  } catch {
    return fallback;
  }
}
